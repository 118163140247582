<template>
  <base-pagination-container
    class="members-list-container"
    :total="total"
    :loading="isLoading"
    :items="members"
    :per-page="perPage"
    :load-first-page="true"
    @load-page="handleLoadOfNewItems"
  >
    <template v-if="canList" #default="{ items, loading }">
      <div
        v-if="isLoading"
        class="members w-100 align-items-center justify-content-center d-flex"
      >
        <b-spinner class="my-5" variant="primary" />
      </div>
      <div v-else>
        <members-table 
          :members="items" 
          :busy="loading" 
          @edit="$emit('edit', $event)" 
          @profile-updated="$emit('profile-updated')"
        />
      </div>
    </template>
    <b-row v-if="!canList" class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="membersPlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t('no-permisions.message') }}
        </p>
      </b-col>
    </b-row>
  </base-pagination-container>
</template>

<script>
import BasePaginationContainer from "@core/components/containers/base/BasePaginationContainer.vue";
import CardWidgetMixin from "@core/mixins/widgets/CardWidgetMixin";
import MembersTable from "./ContactsTable.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";

export default {
  name: "ContactsList",
  components: {
    BasePaginationContainer,
    MembersTable,
  },
  mixins: [CardWidgetMixin],
  props: {
    isLoading: { type: Boolean, required: true },
    total: { type: Number, required: true },
    members: { type: [Array, Object], required: true },
    perPage: {
      type: Number,
      default: 4,
    },
    organizationKey: {
      type: String,
      default: null,
    },
  },
  computed: {
    membersPlaceholder() {
      return MembersPlaceholder;
    },
    canList() {
      return checkPermissions(
        "view",
        "contact",
        this.loggedMemberRoles,
        this.collective,
      );
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
  },
  methods: {
    handleLoadOfNewItems({ page }) {
      this.$emit("handleLoadOfNewItems", { page });
      this.updateBreadcrumbs();
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.$t('contacts.breadcrumb-text.contacts-list'),
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>

<style lang="scss" scoped>
.members-list-container {
  overflow: hidden;
  flex: 1;
}
.toggle-filters-button {
  outline: none;
  box-shadow: none;
  min-width: 25px;
  margin-left: 10px;
}
.members {
  min-height: 451px;
}
</style>
